import 'owl.carousel';
$(document).ready(function(){
  let owl = $('.owl-carousel');
  owl.owlCarousel({
    margin: 15,
    responsiveClass:true,
    navContainer: '.carousel__navigations',
    navText: [],
    center: true,
    nav: true,
    startPosition: 3,
    responsive:{
        0:{
            items:1,
            navContainer: '',
            nav: false
        },
        600:{
            items:2,
            navContainer: '',
            nav:false
        },
        900:{
            items:3,
            nav:false
        },
        1100:{
            items:3,
            nav:true,
            loop:false
        }
    }
});
});


$("[data-fancybox]").fancybox({
    speed: 330,
    buttons: ["close"],
    image: {
        protect: true
    }
});

let popup_items = document.querySelectorAll('.popup__item');
popup_items = Array.prototype.slice.call(popup_items);

function startProgress() {
    const progress = document.querySelector('.progress__wrapper');
    let progress_item = document.createElement('div');
    let step_all = document.querySelector('.popup__step-all');
    step_all.innerHTML = popup_items.length;
    progress_item.classList.add('progress__point');
    const progress_items = popup_items.forEach((item) => {
        let key = item.getAttribute('data-key');
        let point = progress_item.cloneNode(true);
        point.innerHTML = key;
        progress.appendChild(point);
        point.addEventListener('click', (e) => {
            let number = e.target.innerHTML;
            stepQuiz(+number);
        });
    });
    
}

function stepQuiz(number) {
    step_quiz = number;
    let button_step = document.querySelector('.popup__next');
    let span_butt = document.createElement('span');
    span_butt.classList.add('button-box__title');
    let title_node = document.querySelector('.popup__title');
    let title_span = document.createElement('span');
    popup_items.forEach((item) => {
        item.classList.remove('popup__item_active');
    });
    if (number > 0) { 
        button_step.innerHTML = '';
        span_butt.innerHTML = 'Следующий шаг';
        button_step.appendChild(span_butt);
        popup_items[number - 1].classList.add('popup__item_active');
        let title_value = popup_items[number - 1].getAttribute('data-title');
        title_span.innerHTML = title_value;
        title_node.innerHTML = '';
        title_node.appendChild(title_span);
        let step_now = document.querySelector('.popup__step-now');
        step_now.innerHTML = number;
        let i = 1;
        let progress_points = document.querySelectorAll('.progress__point');
        progress_points = Array.prototype.slice.call(progress_points);
        progress_points.forEach((point) => {
            if (i <= number) {
                point.classList.add('progress__point_active');
            } else {
                point.classList.remove('progress__point_active');
            }
            i++;
        });
        addOpenImage();
        let progress_value = ((number - 1)/(popup_items.length - 1))*100;
        let progress_bar = document.querySelector('.progress__bar');
        progress_bar.style.width = progress_value + '%';
        if (step_quiz == popup_items.length) {
            button_step.innerHTML = '';
            span_butt.innerHTML = 'Получить результат';
            button_step.appendChild(span_butt);
            let discount = document.querySelector('.popup__box').getAttribute('data-discount');
            let gift = document.querySelector('.popup__box').getAttribute('data-gift');
            let disc = document.createElement('span');
            disc.classList.add('content__number');
            disc.innerHTML = discount + '%';
            let end_title =  document.createElement('span');
            let title_span = document.createElement('span');
            let title_span_2 = document.createElement('span');
            let title_span_3 = document.createElement('span');
            title_span.innerHTML = 'Ваша скидка ';
            title_span_2.innerHTML = ' и ';
            title_span_3.innerHTML = gift;
            end_title.innerHTML = ' в подарок.';
            title_node.appendChild(title_span);
            title_node.appendChild(disc);
            title_node.appendChild(title_span_2);
            title_node.appendChild(title_span_3);
            title_node.appendChild(end_title);
        }
    } else {
        let message_thanks = document.querySelector('.popup__box').getAttribute('data-message');
        button_step.innerHTML = '';
        span_butt.innerHTML = 'Закрыть окно';
        button_step.appendChild(span_butt);
        title_node.innerHTML = '';
        title_span.innerHTML = message_thanks;
        title_node.appendChild(title_span);
        setTimeout(() => {
            closePopup();
        }, 3000);
    }
}

let step_quiz = 1;
let window_src = './img/win-1.jpg';

function nextQuiz() {
    step_quiz++;
    stepQuiz(step_quiz);
}

function closePopup() {
    let popup = document.querySelector('.popup');
    document.querySelector('body').classList.remove('fixed');
    popup.classList.remove('popup_open');
    const progress = document.querySelector('.progress__wrapper');
    progress.innerHTML = '';
}

function addOpenImage() {
    let img_open = document.querySelector('.popup__size-img');
    img_open.innerHTML = '';
    let img = document.createElement('img');
    img_open.appendChild(img);
    img.setAttribute('src', window_src);
}

function sendForm() {
    if (document.querySelectorAll('.not_error').length == 1) {
        $.ajax({
            url: 'send.php',
            type: 'post',
            data: $('form.popup__box').serialize(),
            beforeSend: function(xhr, textStatus){ 
                 
            },
            success: function(response){
                stepQuiz(0);
            }
        }); 
    } else {
        $('input#phone').blur();
    }
}

let nextButton = document.querySelector('.popup__next');
nextButton.addEventListener('click', (e) => {
    if (0 < step_quiz && step_quiz < popup_items.length) {
        addOpenImage();
        nextQuiz();
    } else if (step_quiz == popup_items.length) {
        sendForm();
    } else if (step_quiz == 0) {
        closePopup();
    }
});

let type_items = document.querySelectorAll('.inp_wr');
type_items = Array.prototype.slice.call(type_items);
type_items.forEach((item) => {
    item.addEventListener('click', (e) => {
        let input = item.querySelector('.radio__btn');
        if (item.classList.contains('popup__type')) {
            let img = item.querySelector('img');
            window_src = img.getAttribute('src');
        }
        input.checked = true;
    });
});




let button_start = document.querySelector('.button-box__start');
button_start.addEventListener('click', (e) => {
    let popup = document.querySelector('.popup');
    document.querySelector('body').classList.add('fixed');
    popup.classList.add('popup_open');
    startProgress();
    stepQuiz(1);
});

$('input#phone').unbind().blur( function(){ 
    var id = $(this).attr('name');
    var val = $(this).val();
    switch(id) {
        case 'phone':
            var rv_phone = /\+\d{3} \(\d{2}\) \d{3}\-\d{2}\-\d{2}/;

            if(val.length > 2 && val != '' && rv_phone.test(val))
            {
               $(this).addClass('not_error');
               $(this).next('.error-box').text('Поле заполнено верно')
                                         .css('color','green')
                                         .animate({'paddingLeft':'10px'},400)
                                         .animate({'paddingLeft':'5px'},400);   
            }
            else
            {
               $(this).removeClass('not_error').addClass('error');
               $(this).next('.error-box').html('&bull; поле "Телефон" обязательно для заполнения')
                                          .css('color','red')
                                          .animate({'paddingLeft':'10px'},400)
                                          .animate({'paddingLeft':'5px'},400);
            }
        break;
    } 
});
jQuery(function($){ $("#phone").mask("+375 (99) 999-99-99"); });
